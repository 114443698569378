<template>
  <div>
    <b-button variant="primary" size="sm" @click="goToDetail()">
        <span class="align-middle">Ver detalle</span>
        
    </b-button>

  </div>
</template>

<script>
import { BButtonGroup, BButton, BModal, BCardText, BRow, BCol, BDropdown, BDropdownItem } from "bootstrap-vue";
import router from '@/router'

import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BModal,
    BCardText,
    BDropdown,
    BDropdownItem,
    BrunaElementLoader
  },

  data: function () {
        return {
            cellValue: null,
        };
    },
    beforeMount() {
        // this.params contains the cell & row information and is made available to this component at creation time
        // see ICellRendererParams below for more details
        this.cellValue = this.getValueToDisplay(this.params);

    },
    methods: {
        goToDetail() {
      // this.$router.push({ name: 'compare-recipe', params: { semana: this.$route.params.semana }})

          this.$router.push({ path: `/molymet/blending/sugerencia-mezcla/` + this.cellValue.data.output_data.mezcla +`/` + this.cellValue.data.output_data.fecha});
        },
        // gets called whenever the user gets the cell to refresh
        refresh(params) {
            // set value into cell again
            this.cellValue = this.getValueToDisplay(params);
        },

        // showDownloadModal() {
        //     this.$refs[
        //         "historico-planificaciones-download-modal-" + this.cellValue.data.id
        //     ].show();
        // },

        getValueToDisplay(params) {
            return params;
            //return params.valueFormatted ? params.valueFormatted : params.value;
        },
    },

}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>