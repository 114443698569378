<template>
  <section>
    <b-card no-body>
      <div class="card-header">
        <!-- Title & SubTitle -->
        <div>
          <b-card-title>Variables de Modelamiento</b-card-title>
          <b-card-sub-title>PROCEDENCIA</b-card-sub-title>
        </div>
        <!-- Card Actions -->
        <div class="heading-elements">
          <ul class="list-inline mb-0">
            <li>
              <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
            </li>
          </ul>
        </div>
      </div>
      <div>
        <b-sidebar id="sidebar-1" title="Sugerencia de mezcla" shadow bg-variant="dark" text-variant="light" width="450px"
          right backdrop>
          <div class="px-2 py-2">
            <p>
              En esta secci&oacute;n usted podr&aacute; simular diversos
              escenarios para abordar las sugerencias de mezclas ofrecidas por
              los algoritmos de AiBruna.
            </p>
            <hr />
            <p>
              <strong>Variables de Modelamiento:</strong> Seleccione las
              procedencias con las que desea simular escenarios.
            </p>
            <p>
              <strong>Ajustes:</strong> Seleccione si dejar&aacute; las
              restricciones por valores de omisi&oacute;n o si desea ajustarlos
              a su medida para realizar la simulaci&oacute;n. Una vez decidido
              este paso, seleccionar el bot&oacute;n “simular”.
            </p>
            <p>
              Una vez decidido este paso, seleccionar el bot&oacute;n “simular”.
            </p>
            <p>
              Luego la plataforma cargar&aacute; las nuevas mezclas a la tabla
              inferior ”Hist&oacute;rico blending” mostrando en sus columnas los
              resultados m&aacute;s relevantes, Adem&aacute;s, podr&aacute;
              realizar diversas acciones.
            </p>
            <hr>
            <p><strong>Variables de Modelamiento:</strong> Seleccione las procedencias con las que desea simular
              escenarios.</p>
            <p><strong>Ajustes:</strong> Seleccione si dejar&aacute; las restricciones por valores de omisi&oacute;n o si
              desea
              ajustarlos a su medida para realizar la simulaci&oacute;n.
              Una vez decidido este paso, seleccionar el bot&oacute;n “simular”.</p>
            <p>Una vez decidido este paso, seleccionar el bot&oacute;n “simular”.</p>
            <p>Luego la plataforma cargar&aacute; las nuevas mezclas a la tabla inferior ”Hist&oacute;rico blending”
              mostrando en sus
              columnas los resultados m&aacute;s relevantes, Adem&aacute;s, podr&aacute; realizar diversas acciones.</p>
            <ul>
              <li>
                Filtrar con el &iacute;cono seg&uacute;n lo que desee (Nombre
                planificaci&oacute;n, Fechas, etc.)
              </li>
              <li>
                eleccionar con doble click las muestras utilizadas para no
                interferir en el stock disponible de nuevas simulaciones.
              </li>
              <li>
                Seleccionar “ver detalle” para visualizar todas las
                especificaciones de las mezclas.
              </li>
              <li>
                Seleccione bot&oacute;n “descargar” si desea descargar el
                archivo con la mezcla sugerida.
              </li>
              <li>
                En la casilla al lado izquierdo de la “Fecha creaci&oacute;n”
                seleccione todas las mezclas que desee comparar. Una vez
                seleccionadas apretar bot&oacute;n comparar para visualizar los
                resultados en una vista de comparaci&oacute;n.
              </li>
              <li>
                Seleccione el bot&oacute;n “Existencias no utilizadas” si desea
                visualizar el stock no utilizado dentro de las simulaciones.
              </li>
            </ul>
          </div>
        </b-sidebar>
      </div>
      <b-card-body style="width: 100%">
        <b-row ref="procedenciaRef">
          <b-col v-for="procedencia in procedencias" :key="procedencia.name">
            <b-form-checkbox v-model="selected" :value="procedencia.value" :unchecked-value="procedencia.value" switch>
              {{ procedencia.name }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-row>
      <b-col>
        <BrunaForm ref="ajustesRef" :fields="this.ajustes.fields" :card="this.ajustes.card">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4" :disabled="selected.length == 0" @click="simulate">Simular</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <ag-grid-table ref="grid-historico-runs" :debug="true" :configUrl="historicoBlendingSugerenciasConfig"
          :dataUrl="historicoBlendingSugerenciasData" @gridReady="onGridReady" @getRowsLoaded="onGetRowsLoaded"
          rowSelection="multiple" :rowClassRules="rowClassRules" :pinnedTopRowData="pinnedTopRowData"
          @selectionChanged="onSelectionChanged" @actionReceived="actionReceived" :actions="actions"
          :getRowNodeId="getRowNodeId">
          <!--
          <template v-slot:actions>
            <div>
              <b-button
                class="mb-2"
                size="sm"
                :disabled="disableCompareRuns"
                @click="compareRuns()"
                variant="primary"
                >Comparar</b-button
              >
            </div>
          </template>
          -->
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
BRow,
BCol,
BButton,
BCardTitle,
BCardSubTitle,
BCardBody,
BCard,
VBPopover,
BFormInput,
BFormCheckbox,
BootstrapVueIcons
} from "bootstrap-vue";

const axios = require("axios");

import VueSlider from 'vue-slider-component';
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import BlendingActions from "./actions/BlendingActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VueSlider,
    BFormInput,
    BFormCheckbox,
    BrunaForm,
    BButton,
    AgGridTable,
    ToastificationContent,
    BlendingActions: BlendingActions,
    BootstrapVueIcons,
  },

  directives: {
  },

  data: function () {
    return {
      historicoBlendingSugerenciasConfig: useApiServices.historicoBlendingSugerenciasConfig,
      historicoBlendingSugerenciasData: useApiServices.historicoBlendingSugerenciasData,
      // historicoStockExistenciasFechas: useApiServices.historicoStockExistenciasFechas,

      data: useApiServices.historicoStockExistenciasData,


      disableCompareRuns: true,
      disableBtnSimular: true,
      procedencias: [
        { name: 'ANT', value: 'ANT' },
        { name: 'CJ', value: 'CJ' },
        { name: 'COL', value: 'COL' },
        { name: 'DP', value: 'DP' },
        { name: 'MCO', value: 'MCO' },
        { name: 'MSA', value: 'MSA' },
        { name: 'MTE', value: 'MTE' },
      ],
      selected: [],
      // options: this.procedencia.map((procedencia) => { return { text: procedencia.name, value: procedencia } }),

      actions: [
        { name: "Ver Detalles", icon: "DownloadCloudIcon", action: this.accionVerDetalles },
      ],

      tableLoaded: false,

      getRowNodeId: (params) => {
        return params.id;
      },

      ajustes: {
        card: {
          title: "Ajustes",
          subtitle: "Selecciona el origen de datos que utilizaras para la planificación",
          tooltip: ""
        },
        fields: [
          {
            label: "Nombre de planificación",
            type: "input",
            id: 'nombre_planificacion',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: "Optimization_" + new Date().valueOf()
          },
          {
            label: "Fecha",
            type: "select",
            id: 'fecha',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null,
            options: [
              { value: null, text: "Selecciona una opcion" },
            ],
          },
          {
            label: "Mo/Cu aceptado (%)",
            type: "range",
            id: 'mo_cu_aceptada',
            rules: 'required|between:0.001,100',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: 16
          },
          {
            label: "Mo/Fe aceptado (%)",
            type: "range",
            id: 'mo_fe_aceptada', 
            rules: 'required|between:0.001,100',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: 11
          },
          {
            label: "Ca aceptado (%)",
            type: "range",
            id: 'ca_aceptada',
            rules: 'required|between:0.001,100',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: 0.8
          },
          {
            label: "W/Mo aceptado (%)",
            type: "range",
            id: 'w_mo_aceptada',
            rules: 'required|between:0.001,100',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: 1.96
          },
          {
            label: "Mo aceptado (%)",
            type: "range",
            id: 'mo_aceptada',
            rules: 'required|between:0.001,100',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: 47
          },
          {
            label: "Libras Mo por ",
            type: "input",
            id: 'libras_mo_',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: 15000
          },

        ]
      },

      gridApi: null,
      itemsData: [],
      pinnedTopRowData: [],
      rowClassRules: {
        'escenario-base': (params) => {
          if (params.data == undefined) {
            return false;
          }
          return params.data.default === 1;
        }
      },
    };
  },
  created() {
    this.updateCards()
  },


  beforeUnmount() {
    this.$pusher.unsubscribe('optimizations');
  },

  beforeRouteLeave(to, from, next) {

    this.$pusher.unsubscribe('optimizations');

    next()
  },


  mounted() {
    let self = this;

    //Se escucha a la llegada de las notificaciones de pusher

    var channel = this.$pusher.subscribe('optimizations');

    channel.bind('OptimizationUpdateEvent', function (data) {

      let userData = JSON.parse(localStorage.getItem("userData"));

      if (userData && userData.client.id == data.client_id) {
        let invocation_id = data.invocation_id

        useApiServices.getInvocation(invocation_id)
          .then((response) => {
            let invocation = response.data

            console.log(invocation)

            if (self.tableLoaded && self.$refs['grid-historico-runs']) {
              self.$refs['grid-historico-runs'].updateRow(invocation_id, invocation)
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    });
  },

  methods: {


    onGetRowsLoaded(gridApi, gridColumnApi, params) {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })

      this.tableLoaded = true;


    },

    accionVerDetalles(data) {

      console.log(data)

      let id = data.data.id;
      this.$router.push({ name: 'molymet-detalle-optimizacion', query: { optimization_id: id } })

    },



    updateCards() {
      useApiServices.getFechasExistencias(this).then((response) => {

        let resp = response.data

        let result = resp.filter((item, index) => {
          return resp.indexOf(item) === index;
        })

        // console.log(result);

        result.sort();

        let fechas_existencias = [
          { value: null, text: "Selecciona una fecha" }
        ]

        result.forEach(item => {
          fechas_existencias.push(
            {
              value: item, text: item
            })

        });

        this.findFieldByName(this.ajustes.fields, 'fecha').options = fechas_existencias;

      })
        .catch((error) => {
          console.log(error);

        });
    },

    findFieldByName(fields, name) {

      return fields.find(field => field.id == name)

    },

    simulate() {

      /* console.log(this.selected);
      console.log(this.ajustes.fields) */
      let data_values = this.ajustes.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      // let _data = { ajustes: data, procedencia: this.selected }
      let data = {
        service_name: "molymet_optimization_00",
        procedencia: this.selected,
        metrics: data_values
      };
      // console.log(_data);

      Promise.all([
        this.$refs.ajustesRef.validate()
      ]).then(([existenciaValid]) => {
        if (existenciaValid) {
          // console.log(data);
          // return 
          useApiServices.postSimulacionesCreate(data)
            .then((response) => {
              console.log(response);

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });
              console.log("refrescando la tabala ahora...");
              //this.$refs.tableExistencias.refreshRows();
              this.$refs['grid-historico-runs'].refreshRows()


            })
            .catch((error) => {
              let message = error.response.data.message;

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });

            });
        }
      });
    },

    actionReceived(action_name, data, params) {
      console.log("actionReceived", { action_name, data, params })
    },

    onGridReady(gridApi, gridColumnApi, params) {
      this.gridApi = gridApi
      // console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })
    },
    onSelectionChanged(selectedRows) {
      this.selectedRows = selectedRows
      this.disableCompareRuns = (selectedRows.length < 2)
      this.disableRellenar = !(selectedRows.length == 1)
    },

  },
};
</script>

<style lang="scss"></style>
  
  
<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
  
  